"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var solid_1 = require("@heroicons/react/24/solid");
var useFileUpload_1 = require("./useFileUpload");
var FileUpload = function (props) {
    var _a = (0, react_1.useState)(false), dragActive = _a[0], setDragActive = _a[1];
    var inputRef = (0, react_1.useRef)(null);
    var _b = (0, useFileUpload_1.useFileUpload)(props), file = _b.file, handleFiles = _b.handleFiles, removeFile = _b.removeFile;
    var handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        }
        else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };
    var handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFiles(e.dataTransfer.files[0]);
        }
    };
    var handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFiles(e.target.files[0]);
        }
    };
    var onButtonClick = function () {
        var _a;
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    return (react_1.default.createElement("div", { className: "flex flex-col items-center" },
        react_1.default.createElement("div", { className: "w-full p-4 border-2 border-dashed rounded-lg text-center cursor-pointer transition-colors ".concat(dragActive ? 'border-rgpd-blue-dark bg-rgpd-blue-light/10' : 'border-gray-300'), onDragEnter: handleDrag, onDragLeave: handleDrag, onDragOver: handleDrag, onDrop: handleDrop, onClick: onButtonClick },
            react_1.default.createElement("input", { ref: inputRef, type: "file", accept: props.acceptedFileTypes, onChange: handleChange, className: "hidden" }),
            file ? (react_1.default.createElement("div", { className: "flex items-center justify-center space-x-2" },
                react_1.default.createElement(solid_1.DocumentIcon, { className: "h-6 w-6 text-rgpd-blue-dark" }),
                react_1.default.createElement("span", { className: "text-sm font-medium text-gray-700" }, file.name),
                react_1.default.createElement("button", { onClick: function (e) {
                        e.stopPropagation();
                        removeFile();
                    }, className: "text-red-500 hover:text-red-700" },
                    react_1.default.createElement(solid_1.XMarkIcon, { className: "h-5 w-5" })))) : (react_1.default.createElement("div", { className: "space-y-2" },
                react_1.default.createElement(solid_1.CloudArrowUpIcon, { className: "h-10 w-10 text-gray-400 mx-auto" }),
                react_1.default.createElement("p", { className: "text-sm text-gray-600" }, "Glissez-d\u00E9posez un fichier ici, ou cliquez pour s\u00E9lectionner"),
                react_1.default.createElement("p", { className: "text-xs text-gray-500" },
                    props.acceptedFileTypes,
                    " (Max. ",
                    props.maxFileSize,
                    "MB)"))))));
};
exports.default = FileUpload;
