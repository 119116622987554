"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DPE_STEPS = void 0;
exports.DPE_STEPS = {
    NEW: 1,
    NO_OWNER: 2,
    IDENTIFIED: 3,
    IN_PROCESS: 4,
    PROCESSED: 5,
};
