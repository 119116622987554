"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JWTHeader = JWTHeader;
exports.JWTAuthorization = JWTAuthorization;
// return a header object with the JWT token from the Redux store
function JWTHeader(jwt) {
    return {
        headers: {
            Authorization: "Bearer ".concat(jwt),
        },
    };
}
function JWTAuthorization(jwt) {
    return "Bearer ".concat(jwt);
}
