"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLabelByValue = exports.getValueByLabel = exports.roles = void 0;
var roles = [
    { label: 'GESTIONNAIRE DE L\'IMMEUBLE', value: 1 },
    { label: 'CHARGÉ DE LA QUALIFICATION', value: 2 },
    { label: 'TRANSACTIONNAIRE', value: 3 },
    { label: 'INSPECTEUR DES TRAVAUX', value: 4 },
    { label: 'INTERVENANT EXTÉRIEUR', value: 5 },
];
exports.roles = roles;
var getValueByLabel = function (label) {
    var foundRole = roles.find(function (r) { return r.label === label; });
    return foundRole ? foundRole.value : null;
};
exports.getValueByLabel = getValueByLabel;
var getLabelByValue = function (value) {
    var foundRole = roles.find(function (r) { return r.value === value; });
    return foundRole ? foundRole.label : null;
};
exports.getLabelByValue = getLabelByValue;
