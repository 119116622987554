"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeCoproAddress = exports.addCoproAddress = exports.updateCoproAddress = exports.removeCoproFormData = exports.updateCoproFormData = exports.addCoproFormData = exports.setCoprosFormData = exports.updateSelectedDate = exports.setSelectedDates = exports.resetBodaccHistory = exports.setReloadPerf = exports.setSelectedRow = exports.removeSelectedRow = exports.addSelectedRow = exports.deleteAttribution = exports.createAttribution = exports.fetchBodaccHistory = exports.fetchBodacc = exports.fetchBauxCommerciauxAEcheance = exports.fetchBauxCommerciaux = exports.fetchPerformance = exports.fetchUsers = exports.fetchCoproAttribution = exports.fetchCopro = void 0;
// reducer.ts
var toolkit_1 = require("@reduxjs/toolkit");
var api_1 = require("../utils/api");
var JWTHeader_1 = require("../utils/JWTHeader");
var AuthReducer_1 = require("./AuthReducer");
var ToastrNotif_module_1 = require("../components/common/Notification/ToastrNotif.module");
var initialState = {
    performance: null,
    bauxCommerciaux: null,
    bauxCommerciauxAEcheance: null,
    bodacc: null,
    bodaccHistory: null,
    copros: [],
    coprosFormData: [],
    users: [],
    selectedRows: [[], [], [], [], []], // one array for each activty setup step
    reloadPerf: false,
    selectedDates: {},
};
exports.fetchCopro = (0, toolkit_1.createAsyncThunk)('copro/copro', function (_arg, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var state, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = thunkApi.getState();
                if (!state.auth.company) return [3 /*break*/, 2];
                return [4 /*yield*/, fetch((0, api_1.getCopro)(state.auth.company.id, true), (0, JWTHeader_1.JWTHeader)(state.auth.token))];
            case 1:
                response = _a.sent();
                if (response.ok) {
                    return [2 /*return*/, response.json()];
                }
                else {
                    if (response.status === 401) {
                        thunkApi.dispatch((0, AuthReducer_1.setToken)(null));
                    }
                    console.error('Error fetching copros');
                    (0, ToastrNotif_module_1.showToast)('error', 'Une erreur est survenue lors du chargement des données.');
                    return [2 /*return*/, Promise.reject()];
                }
                return [3 /*break*/, 3];
            case 2: return [2 /*return*/, Promise.reject()];
            case 3: return [2 /*return*/];
        }
    });
}); });
exports.fetchCoproAttribution = (0, toolkit_1.createAsyncThunk)('copro/attribution', function (_arg, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var state, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = thunkApi.getState();
                if (!state.auth.company) return [3 /*break*/, 2];
                return [4 /*yield*/, fetch((0, api_1.getAttribution)(state.auth.company.id), (0, JWTHeader_1.JWTHeader)(state.auth.token))];
            case 1:
                response = _a.sent();
                if (response.ok) {
                    return [2 /*return*/, response.json()];
                }
                else {
                    if (response.status === 401) {
                        thunkApi.dispatch((0, AuthReducer_1.setToken)(null));
                    }
                    console.error('Error fetching copros');
                    (0, ToastrNotif_module_1.showToast)('error', 'Une erreur est survenue lors du chargement des données.');
                    return [2 /*return*/, Promise.reject()];
                }
                return [3 /*break*/, 3];
            case 2: return [2 /*return*/, Promise.reject()];
            case 3: return [2 /*return*/];
        }
    });
}); });
exports.fetchUsers = (0, toolkit_1.createAsyncThunk)('copro/users', function (_arg, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var state, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = thunkApi.getState();
                if (!state.auth.company) return [3 /*break*/, 2];
                return [4 /*yield*/, fetch((0, api_1.getUsers)(state.auth.company.id), (0, JWTHeader_1.JWTHeader)(state.auth.token))];
            case 1:
                response = _a.sent();
                if (response.ok) {
                    return [2 /*return*/, response.json()];
                }
                else {
                    if (response.status === 401) {
                        thunkApi.dispatch((0, AuthReducer_1.setToken)(null));
                    }
                    console.error('Error fetching copros');
                    (0, ToastrNotif_module_1.showToast)('error', 'Une erreur est survenue lors du chargement des données.');
                    return [2 /*return*/, Promise.reject()];
                }
                return [3 /*break*/, 3];
            case 2: return [2 /*return*/, Promise.reject()];
            case 3: return [2 /*return*/];
        }
    });
}); });
exports.fetchPerformance = (0, toolkit_1.createAsyncThunk)('copro/performance', function (_arg, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var state, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = thunkApi.getState();
                if (!state.auth.company) return [3 /*break*/, 2];
                return [4 /*yield*/, fetch((0, api_1.getCoproPerformance)(state.auth.company.id), (0, JWTHeader_1.JWTHeader)(state.auth.token))];
            case 1:
                response = _a.sent();
                if (response.ok) {
                    return [2 /*return*/, response.json()];
                }
                else {
                    if (response.status === 401) {
                        thunkApi.dispatch((0, AuthReducer_1.setToken)(null));
                    }
                    console.error('Error fetching copros');
                    (0, ToastrNotif_module_1.showToast)('error', 'Une erreur est survenue lors du chargement des données.');
                    return [2 /*return*/, Promise.reject()];
                }
                return [3 /*break*/, 3];
            case 2: return [2 /*return*/, Promise.reject()];
            case 3: return [2 /*return*/];
        }
    });
}); });
exports.fetchBauxCommerciaux = (0, toolkit_1.createAsyncThunk)('copro/baux/commerciaux', function (_arg, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var state, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = thunkApi.getState();
                if (!state.auth.company) return [3 /*break*/, 2];
                return [4 /*yield*/, fetch((0, api_1.getBauxCommerciaux)(state.auth.company.id), (0, JWTHeader_1.JWTHeader)(state.auth.token))];
            case 1:
                response = _a.sent();
                if (response.ok) {
                    return [2 /*return*/, response.json()];
                }
                else {
                    if (response.status === 401) {
                        thunkApi.dispatch((0, AuthReducer_1.setToken)(null));
                    }
                    console.error('Error fetching copros');
                    (0, ToastrNotif_module_1.showToast)('error', 'Une erreur est survenue lors du chargement des données.');
                    return [2 /*return*/, Promise.reject()];
                }
                return [3 /*break*/, 3];
            case 2: return [2 /*return*/, Promise.reject()];
            case 3: return [2 /*return*/];
        }
    });
}); });
exports.fetchBauxCommerciauxAEcheance = (0, toolkit_1.createAsyncThunk)('copro/baux/commerciaux/echeance', function (_arg, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var state, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = thunkApi.getState();
                if (!state.auth.company) return [3 /*break*/, 2];
                return [4 /*yield*/, fetch((0, api_1.getBauxCommerciauxAEcheance)(state.auth.company.id), (0, JWTHeader_1.JWTHeader)(state.auth.token))];
            case 1:
                response = _a.sent();
                if (response.ok) {
                    return [2 /*return*/, response.json()];
                }
                else {
                    if (response.status === 401) {
                        thunkApi.dispatch((0, AuthReducer_1.setToken)(null));
                    }
                    console.error('Error fetching copros');
                    (0, ToastrNotif_module_1.showToast)('error', 'Une erreur est survenue lors du chargement des données.');
                    return [2 /*return*/, Promise.reject()];
                }
                return [3 /*break*/, 3];
            case 2: return [2 /*return*/, Promise.reject()];
            case 3: return [2 /*return*/];
        }
    });
}); });
exports.fetchBodacc = (0, toolkit_1.createAsyncThunk)('copro/bodaccs', function (_arg, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var state, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = thunkApi.getState();
                if (!state.auth.company) return [3 /*break*/, 2];
                return [4 /*yield*/, fetch((0, api_1.getBodacc)(state.auth.company.id), (0, JWTHeader_1.JWTHeader)(state.auth.token))];
            case 1:
                response = _a.sent();
                if (response.ok) {
                    return [2 /*return*/, response.json()];
                }
                else {
                    if (response.status === 401) {
                        thunkApi.dispatch((0, AuthReducer_1.setToken)(null));
                    }
                    console.error('Error fetching copros');
                    (0, ToastrNotif_module_1.showToast)('error', 'Une erreur est survenue lors du chargement des données.');
                    return [2 /*return*/, Promise.reject()];
                }
                return [3 /*break*/, 3];
            case 2: return [2 /*return*/, Promise.reject()];
            case 3: return [2 /*return*/];
        }
    });
}); });
exports.fetchBodaccHistory = (0, toolkit_1.createAsyncThunk)('copro/bodaccs/history', function (SIRET, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var state, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = thunkApi.getState();
                return [4 /*yield*/, fetch((0, api_1.getBodaccHistory)(SIRET), (0, JWTHeader_1.JWTHeader)(state.auth.token))];
            case 1:
                response = _a.sent();
                if (response.ok) {
                    return [2 /*return*/, response.json()];
                }
                else {
                    if (response.status === 401) {
                        thunkApi.dispatch((0, AuthReducer_1.setToken)(null));
                    }
                    console.error('Error fetching copros');
                    (0, ToastrNotif_module_1.showToast)('error', 'Une erreur est survenue lors du chargement des données.');
                    return [2 /*return*/, Promise.reject()];
                }
                return [2 /*return*/];
        }
    });
}); });
exports.createAttribution = (0, toolkit_1.createAsyncThunk)('copro/create/attribution', function (args, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var state, requestInit, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = thunkApi.getState();
                requestInit = (0, JWTHeader_1.JWTHeader)(state.auth.token);
                requestInit.headers['Content-Type'] = 'application/json';
                requestInit.method = 'POST';
                requestInit.body = JSON.stringify({ fonctionType: args.role, user: args.userId, toNotifyForNewDpe: true });
                return [4 /*yield*/, fetch((0, api_1.setAttribution)(args.coproId), requestInit)];
            case 1:
                response = _a.sent();
                if (response.ok) {
                    return [2 /*return*/, response.json()];
                }
                else {
                    if (response.status === 401) {
                        thunkApi.dispatch((0, AuthReducer_1.setToken)(null));
                    }
                    console.error('Error fetching copros');
                    (0, ToastrNotif_module_1.showToast)('error', 'Une erreur est survenue lors de la mise à jour des données.');
                    return [2 /*return*/, Promise.reject()];
                }
                return [2 /*return*/];
        }
    });
}); });
exports.deleteAttribution = (0, toolkit_1.createAsyncThunk)('copro/delete/attribution', function (attributionId, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var state, requestInit, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = thunkApi.getState();
                requestInit = (0, JWTHeader_1.JWTHeader)(state.auth.token);
                requestInit.headers['Content-Type'] = 'application/json';
                requestInit.method = 'DELETE';
                return [4 /*yield*/, fetch((0, api_1.delAttribution)(attributionId), requestInit)];
            case 1:
                response = _a.sent();
                if (response.ok) {
                    return [2 /*return*/, Promise.resolve(attributionId)];
                }
                else {
                    if (response.status === 401) {
                        thunkApi.dispatch((0, AuthReducer_1.setToken)(null));
                    }
                    console.error('Error fetching copros');
                    (0, ToastrNotif_module_1.showToast)('error', 'Une erreur est survenue lors de la mise à jour des données.');
                    return [2 /*return*/, Promise.reject()];
                }
                return [2 /*return*/];
        }
    });
}); });
var coproSlice = (0, toolkit_1.createSlice)({
    name: 'copro',
    initialState: initialState,
    reducers: {
        addSelectedRow: function (state, action) {
            if (state.selectedRows[action.payload.step - 1].indexOf(action.payload.row) === -1) {
                state.selectedRows[action.payload.step - 1].push(action.payload.row);
            }
        },
        removeSelectedRow: function (state, action) {
            var index = state.selectedRows[action.payload.step - 1].indexOf(action.payload.row);
            if (index >= 0) {
                state.selectedRows[action.payload.step - 1].splice(index, 1);
            }
        },
        setSelectedRow: function (state, action) {
            state.selectedRows[action.payload.step - 1] = action.payload.rows;
        },
        setReloadPerf: function (state, action) {
            state.reloadPerf = action.payload;
        },
        resetBodaccHistory: function (state) {
            state.bodaccHistory = null;
        },
        setSelectedDates: function (state, action) {
            state.selectedDates = action.payload;
        },
        updateSelectedDate: function (state, action) {
            var _a = action.payload, coproId = _a.coproId, updatedDate = _a.updatedDate;
            state.selectedDates[coproId] = updatedDate;
        },
        setCoprosFormData: function (state, action) {
            state.coprosFormData = action.payload;
        },
        addCoproFormData: function (state, action) {
            state.coprosFormData.push(action.payload);
        },
        updateCoproFormData: function (state, action) {
            var _a = action.payload, id = _a.id, data = _a.data;
            var index = state.coprosFormData.findIndex(function (copro) { return copro.id === id; });
            if (index !== -1) {
                state.coprosFormData[index] = __assign(__assign({}, state.coprosFormData[index]), data);
            }
        },
        removeCoproFormData: function (state, action) {
            var coproId = action.payload.coproId;
            var index = state.coprosFormData.findIndex(function (copro) { return copro.id === coproId; });
            if (index !== -1) {
                state.coprosFormData.splice(index, 1);
            }
        },
        updateCoproAddress: function (state, action) {
            var _a = action.payload, coproId = _a.coproId, addressId = _a.addressId, address = _a.address;
            var copro = state.coprosFormData.find(function (c) { return c.id === coproId; });
            if (copro) {
                var addressIndex = copro.addresses.findIndex(function (a) { return a.id === addressId; });
                if (addressIndex !== -1) {
                    copro.addresses[addressIndex] = __assign(__assign({}, copro.addresses[addressIndex]), address);
                }
            }
        },
        addCoproAddress: function (state, action) {
            var _a = action.payload, coproId = _a.coproId, address = _a.address;
            var copro = state.coprosFormData.find(function (c) { return c.id === coproId; });
            if (copro) {
                copro.addresses.push(address);
            }
        },
        removeCoproAddress: function (state, action) {
            var _a = action.payload, coproId = _a.coproId, addressId = _a.addressId;
            var copro = state.coprosFormData.find(function (c) { return c.id === coproId; });
            if (copro) {
                copro.addresses = copro.addresses.filter(function (address) { return address.id !== addressId; });
            }
        },
    },
    extraReducers: function (builder) {
        builder.addCase(exports.fetchPerformance.fulfilled, function (state, action) {
            state.performance = action.payload;
        });
        builder.addCase(exports.fetchCopro.fulfilled, function (state, action) {
            state.copros = action.payload.results;
        });
        builder.addCase(exports.fetchUsers.fulfilled, function (state, action) {
            var userMap = {};
            action.payload.results.forEach(function (data) {
                if (!userMap[data.user.id]) {
                    userMap[data.user.id] = __assign(__assign({}, data.user), { fonctions: [] });
                }
                userMap[data.user.id].fonctions.push({
                    id: data.id,
                    type: data.type,
                    company: data.company,
                });
            });
            state.users = Object.values(userMap);
        });
        builder.addCase(exports.fetchCoproAttribution.fulfilled, function (state, action) {
            state.copros = state.copros.map(function (copro) {
                return (__assign(__assign({}, copro), { attributions: action.payload.filter(function (attr) { return attr.copro.id == copro.id; }) }));
            });
        });
        builder.addCase(exports.createAttribution.fulfilled, function (state, action) {
            state.copros = state.copros.map(function (copro) {
                if (copro.id == action.payload.copro.id) {
                    var attributions = copro.attributions;
                    attributions.push(action.payload);
                    return __assign(__assign({}, copro), { attributions: attributions });
                }
                else {
                    return copro;
                }
            });
        });
        builder.addCase(exports.deleteAttribution.fulfilled, function (state, action) {
            state.copros = state.copros.map(function (copro) {
                var index = copro.attributions.findIndex((function (attr) { return attr.id === action.payload; }));
                if (index >= 0) {
                    var attributions = copro.attributions;
                    attributions.splice(index, 1);
                    return __assign(__assign({}, copro), { attributions: attributions });
                }
                else {
                    return copro;
                }
            });
        });
        builder.addCase(exports.fetchBauxCommerciaux.fulfilled, function (state, action) {
            state.bauxCommerciaux = action.payload;
        });
        builder.addCase(exports.fetchBauxCommerciauxAEcheance.fulfilled, function (state, action) {
            state.bauxCommerciauxAEcheance = action.payload;
        });
        builder.addCase(exports.fetchBodacc.fulfilled, function (state, action) {
            // parse action.payload.bodacc listeetablissements, acte, listepersonnes, modificationsgenerales, jugement, listeprecedentproprietaire from json to object
            action.payload.forEach(function (bodacc) {
                if (bodacc.listeetablissements)
                    bodacc.listeetablissements = JSON.parse(bodacc.listeetablissements);
                if (bodacc.acte)
                    bodacc.acte = JSON.parse(bodacc.acte);
                if (bodacc.listepersonnes)
                    bodacc.listepersonnes = JSON.parse(bodacc.listepersonnes);
                if (bodacc.modificationsgenerales)
                    bodacc.modificationsgenerales = JSON.parse(bodacc.modificationsgenerales);
                if (bodacc.jugement)
                    bodacc.jugement = JSON.parse(bodacc.jugement);
                if (bodacc.listeprecedentproprietaire)
                    bodacc.listeprecedentproprietaire = JSON.parse(bodacc.listeprecedentproprietaire);
            });
            state.bodacc = action.payload;
        });
        builder.addCase(exports.fetchBodaccHistory.fulfilled, function (state, action) {
            // parse action.payload.bodacc listeetablissements, acte, listepersonnes, modificationsgenerales, jugement, listeprecedentproprietaire from json to object
            action.payload.forEach(function (bodacc) {
                if (bodacc.listeetablissements)
                    bodacc.listeetablissements = JSON.parse(bodacc.listeetablissements);
                if (bodacc.acte)
                    bodacc.acte = JSON.parse(bodacc.acte);
                if (bodacc.listepersonnes)
                    bodacc.listepersonnes = JSON.parse(bodacc.listepersonnes);
                if (bodacc.modificationsgenerales)
                    bodacc.modificationsgenerales = JSON.parse(bodacc.modificationsgenerales);
                if (bodacc.jugement)
                    bodacc.jugement = JSON.parse(bodacc.jugement);
                if (bodacc.listeprecedentproprietaire)
                    bodacc.listeprecedentproprietaire = JSON.parse(bodacc.listeprecedentproprietaire);
            });
            state.bodaccHistory = action.payload;
        });
    },
});
exports.addSelectedRow = (_a = coproSlice.actions, _a.addSelectedRow), exports.removeSelectedRow = _a.removeSelectedRow, exports.setSelectedRow = _a.setSelectedRow, exports.setReloadPerf = _a.setReloadPerf, exports.resetBodaccHistory = _a.resetBodaccHistory, exports.setSelectedDates = _a.setSelectedDates, exports.updateSelectedDate = _a.updateSelectedDate, exports.setCoprosFormData = _a.setCoprosFormData, exports.addCoproFormData = _a.addCoproFormData, exports.updateCoproFormData = _a.updateCoproFormData, exports.removeCoproFormData = _a.removeCoproFormData, exports.updateCoproAddress = _a.updateCoproAddress, exports.addCoproAddress = _a.addCoproAddress, exports.removeCoproAddress = _a.removeCoproAddress;
exports.default = coproSlice.reducer;
