"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DPE_QUALIF_STEPS = void 0;
exports.DPE_QUALIF_STEPS = {
    COMPLETING_OWNER_COUNT: 1,
    COMPLETING_OWNERS_DETAILS: 2,
    REDIRECTION_TO_DPE_OR_IDENTIFIED: 3,
    DISPLAY_OWNERS_DETAILS: 4,
    MODIFICATION_SINGLE_OWNER_DETAILS: 5,
};
