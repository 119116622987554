"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var solid_1 = require("@heroicons/react/24/solid"); // Importing the hero icon
var Popup = function (_a) {
    var _b = _a.isOpen, isOpen = _b === void 0 ? false : _b, onClose = _a.onClose, _c = _a.message, message = _c === void 0 ? '' : _c, _d = _a.title, title = _d === void 0 ? 'RGPD Copro' : _d, children = _a.children, _e = _a.additionalClass, additionalClass = _e === void 0 ? '' : _e, _f = _a.width, width = _f === void 0 ? 'fit-content' : _f, _g = _a.height, height = _g === void 0 ? 'fit-content' : _g, _h = _a.disableCloseButton, disableCloseButton = _h === void 0 ? false : _h;
    var popupRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        if (isOpen) {
            // Disable scrolling on the main window when the popup is open
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            document.body.style.touchAction = 'none'; // Prevent touch scrolling on mobile devices
            document.documentElement.style.touchAction = 'none'; // Prevent touch scrolling on mobile devices
        }
        else {
            // Enable scrolling on the main window when the popup is closed
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
            document.body.style.touchAction = 'auto'; // Re-enable touch scrolling on mobile devices
            document.documentElement.style.touchAction = 'auto'; // Re-enable touch scrolling on mobile devices
        }
        return function () {
            // Cleanup when the component is unmounted or when isOpen changes
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
            document.body.style.touchAction = 'auto';
            document.documentElement.style.touchAction = 'auto';
        };
    }, [isOpen]);
    (0, react_1.useEffect)(function () {
        var handleClickOutside = function (event) {
            if (popupRef.current && !popupRef.current.contains(event.target) && onClose) {
                onClose();
            }
        };
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return function () {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);
    if (!isOpen)
        return null;
    return (react_1.default.createElement("div", { className: "fixed inset-0 flex items-center justify-center bg-black bg-opacity-50", style: { zIndex: 1000 } },
        react_1.default.createElement("div", { ref: popupRef, className: 'bg-white p-6 rounded-lg shadow-lg flex flex-col ' + additionalClass, style: { width: width, maxWidth: '90vw', height: height, maxHeight: '90vh' } },
            react_1.default.createElement("div", { className: "flex justify-between items-start" },
                react_1.default.createElement("h2", { className: "text-xl font-bold text-black" }, title),
                !disableCloseButton && (react_1.default.createElement("button", { className: "text-gray-500 hover:text-gray-700", onClick: onClose },
                    react_1.default.createElement(solid_1.XCircleIcon, { className: "w-6 h-6" })))),
            react_1.default.createElement("div", { className: "relative flex flex-grow overflow-y-auto mt-4 flex-col" }, message ? (react_1.default.createElement("p", { className: "text-gray-600 mb-4" }, message)) : (children)))));
};
exports.default = Popup;
