"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTypeFilteredIdentifiedDpe = exports.setTypeFilteredNewDpe = exports.setTypeFiltered = exports.setMonthFilteredIdentifiedDpe = exports.setMonthFilteredNewDpe = exports.setMonthFiltered = exports.setDpeClosedData = exports.setDpeData = exports.resetAll = exports.setIsDpeIdentified = exports.resetOwners = exports.setOwners = exports.setOwner = exports.removeOwner = exports.updateOwnerOfDpe = exports.addOwnerToDpe = exports.addOwner = exports.initOwners = exports.resetNbOwner = exports.setNbOwner = exports.resetCurrentStep = exports.setCurrentStep = exports.resetSelectedDpe = exports.setSelectedDpe = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    selectedDpe: null,
    currentStep: null,
    nbOwner: null,
    owners: [],
    isDpeIdentified: false,
    monthFiltered: null,
    monthFilteredNewDpe: null,
    monthFilteredIdentifiedDpe: null,
    dpeData: [],
    dpeClosedData: [],
};
var dpeSlice = (0, toolkit_1.createSlice)({
    name: 'dpe',
    initialState: initialState,
    reducers: {
        setCurrentStep: function (state, action) {
            state.currentStep = action.payload;
        },
        resetCurrentStep: function (state) {
            state.currentStep = null;
        },
        setSelectedDpe: function (state, action) {
            state.selectedDpe = action.payload;
        },
        resetSelectedDpe: function (state) {
            state.selectedDpe = null;
        },
        setNbOwner: function (state, action) {
            state.nbOwner = action.payload;
        },
        resetNbOwner: function (state) {
            state.nbOwner = null;
        },
        initOwners: function (state, action) {
            var initialOwner = {
                firstname: '',
                lastname: '',
                postalCode: action.payload[0],
                city: action.payload[1],
                address: '',
                livingIn: true,
                email: '',
                phone: '',
                civility: 1,
                floorNumber: '',
                doorNumber: '',
                surface: 0,
                contacts: [],
                interaction: null,
            };
            var owners = [];
            for (var i = 0; i < state.nbOwner; i++) {
                owners.push(initialOwner);
            }
            state.owners = owners;
        },
        setOwner: function (state, action) {
            state.owners[action.payload[1]] = action.payload[0];
        },
        addOwner: function (state, action) {
            state.owners.push(action.payload[0]);
            state.nbOwner += 1;
        },
        addOwnerToDpe: function (state, action) {
            var _a = action.payload, dpeId = _a[0], newOwner = _a[1];
            var dpeToUpdate = state.dpeData.find(function (dpe) { return dpe.id === dpeId; });
            if (dpeToUpdate) {
                dpeToUpdate.owners.push(newOwner);
            }
        },
        updateOwnerOfDpe: function (state, action) {
            var _a = action.payload, dpeId = _a[0], updatedOwner = _a[1];
            var dpeToUpdate = state.dpeData.find(function (dpe) { return dpe.id === dpeId; });
            if (dpeToUpdate) {
                var ownerIndex = dpeToUpdate.owners.findIndex(function (owner) { return owner.id === updatedOwner.id; });
                if (ownerIndex >= 0) {
                    dpeToUpdate.owners[ownerIndex] = __assign(__assign({}, dpeToUpdate.owners[ownerIndex]), updatedOwner);
                }
            }
        },
        removeOwner: function (state, action) {
            state.owners = state.owners.filter(function (_, index) { return index !== action.payload; });
            state.nbOwner -= 1;
        },
        setOwners: function (state, action) {
            state.owners = action.payload;
        },
        resetOwners: function (state) {
            state.owners = [];
        },
        setIsDpeIdentified: function (state, action) {
            state.isDpeIdentified = action.payload;
        },
        resetAll: function (state) {
            state.nbOwner = null;
            state.selectedDpe = null;
            state.currentStep = null;
            state.owners = [];
            state.isDpeIdentified = false;
        },
        setDpeData: function (state, action) {
            state.dpeData = action.payload;
        },
        setDpeClosedData: function (state, action) {
            state.dpeClosedData = action.payload;
        },
        setMonthFiltered: function (state, action) {
            var _a;
            state.monthFiltered = { date: action.payload, type: ((_a = state.monthFilteredIdentifiedDpe) === null || _a === void 0 ? void 0 : _a.type) || 'reception' };
        },
        setTypeFiltered: function (state, action) {
            var _a;
            if (action.payload == 'reception' || action.payload == 'etablissement') {
                state.monthFilteredNewDpe = { date: (_a = state.monthFilteredNewDpe) === null || _a === void 0 ? void 0 : _a.date, type: action.payload };
            }
            else {
                throw new Error('Invalid type');
            }
        },
        setMonthFilteredNewDpe: function (state, action) {
            var _a;
            state.monthFilteredNewDpe = { date: action.payload, type: ((_a = state.monthFilteredIdentifiedDpe) === null || _a === void 0 ? void 0 : _a.type) || 'reception' };
        },
        setTypeFilteredNewDpe: function (state, action) {
            var _a;
            if (action.payload == 'reception' || action.payload == 'etablissement') {
                state.monthFilteredNewDpe = { date: (_a = state.monthFilteredNewDpe) === null || _a === void 0 ? void 0 : _a.date, type: action.payload };
            }
            else {
                throw new Error('Invalid type');
            }
        },
        setMonthFilteredIdentifiedDpe: function (state, action) {
            var _a;
            state.monthFilteredIdentifiedDpe = { date: action.payload, type: ((_a = state.monthFilteredIdentifiedDpe) === null || _a === void 0 ? void 0 : _a.type) || 'reception' };
        },
        setTypeFilteredIdentifiedDpe: function (state, action) {
            var _a;
            if (action.payload == 'reception' || action.payload == 'etablissement') {
                state.monthFilteredNewDpe = { date: (_a = state.monthFilteredNewDpe) === null || _a === void 0 ? void 0 : _a.date, type: action.payload };
            }
            else {
                throw new Error('Invalid type');
            }
        },
    },
});
exports.setSelectedDpe = (_a = dpeSlice.actions, _a.setSelectedDpe), exports.resetSelectedDpe = _a.resetSelectedDpe, exports.setCurrentStep = _a.setCurrentStep, exports.resetCurrentStep = _a.resetCurrentStep, exports.setNbOwner = _a.setNbOwner, exports.resetNbOwner = _a.resetNbOwner, exports.initOwners = _a.initOwners, exports.addOwner = _a.addOwner, exports.addOwnerToDpe = _a.addOwnerToDpe, exports.updateOwnerOfDpe = _a.updateOwnerOfDpe, exports.removeOwner = _a.removeOwner, exports.setOwner = _a.setOwner, exports.setOwners = _a.setOwners, exports.resetOwners = _a.resetOwners, exports.setIsDpeIdentified = _a.setIsDpeIdentified, exports.resetAll = _a.resetAll, exports.setDpeData = _a.setDpeData, exports.setDpeClosedData = _a.setDpeClosedData, exports.setMonthFiltered = _a.setMonthFiltered, exports.setMonthFilteredNewDpe = _a.setMonthFilteredNewDpe, exports.setMonthFilteredIdentifiedDpe = _a.setMonthFilteredIdentifiedDpe, exports.setTypeFiltered = _a.setTypeFiltered, exports.setTypeFilteredNewDpe = _a.setTypeFilteredNewDpe, exports.setTypeFilteredIdentifiedDpe = _a.setTypeFilteredIdentifiedDpe;
exports.default = dpeSlice.reducer;
