"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCopro = getCopro;
exports.getAttribution = getAttribution;
exports.getCoproPerformance = getCoproPerformance;
exports.getUsers = getUsers;
exports.setAttribution = setAttribution;
exports.delAttribution = delAttribution;
exports.getBauxCommerciaux = getBauxCommerciaux;
exports.getBauxCommerciauxAEcheance = getBauxCommerciauxAEcheance;
exports.getBodacc = getBodacc;
exports.getBodaccHistory = getBodaccHistory;
exports.getCoproSoonExpired = getCoproSoonExpired;
exports.fetchNewDpes = fetchNewDpes;
exports.getRealEstateSoldModule = getRealEstateSoldModule;
exports.getCadastreModule = getCadastreModule;
function getCopro(id, filterValidatedByUser, filterValidatedByAdmin, sortField, sortDirection) {
    return "/api/companies/".concat(id.toString(), "/copros?limit=10000").concat(filterValidatedByUser ? '&filterValidatedByUser=' + filterValidatedByUser : '').concat(filterValidatedByAdmin ? '&filterValidatedByAdmin=' + filterValidatedByAdmin : '').concat(sortField ? '&sortField=' + sortField : '').concat(sortDirection ? '&sortDirection=' + sortDirection : '');
}
function getAttribution(id) {
    return "/api/companies/".concat(id.toString(), "/validated-copros/attributions");
}
function getCoproPerformance(id) {
    return "/api/companies/".concat(id.toString(), "/dpes/stats/steps");
}
function getUsers(id, filterIncludeSubcontractors) {
    var url = "/api/companies/".concat(id.toString(), "/fonctions?limit=10000");
    if (filterIncludeSubcontractors) {
        url += '&filterIncludeSubcontractors=true';
    }
    return url;
}
function setAttribution(id) {
    return "/api/copros/".concat(id.toString(), "/attributions");
}
function delAttribution(id) {
    return "/api/attributions/".concat(id.toString());
}
function getBauxCommerciaux(id) {
    return "/api/companies/".concat(id.toString(), "/baux/deadlines");
}
function getBauxCommerciauxAEcheance(id) {
    return "/api/companies/".concat(id.toString(), "/baux/deadlines?months=6");
}
function getBodacc(id) {
    return "/api/companies/".concat(id.toString(), "/bodaccs");
}
function getBodaccHistory(SIRET) {
    return "/api/bodaccs/".concat(SIRET, "/history");
}
function getCoproSoonExpired(id, months) {
    return "/api/companies/".concat(id, "/validated-copros/rnics/expired/").concat(months);
}
function fetchNewDpes(id, dpeState, dpeStep, month, filterType) {
    if (filterType === 'etablissement') {
        return "/api/companies/".concat(id.toString(), "/dpes?limit=100000").concat(dpeState ? '&filterDPEState=' + dpeState : '').concat(dpeStep ? '&filterDPEStep=' + dpeStep : '').concat(month ? '&filterMonthEstablishedAt=' + month : '');
    }
    else {
        return "/api/companies/".concat(id.toString(), "/dpes?limit=100000").concat(dpeState ? '&filterDPEState=' + dpeState : '').concat(dpeStep ? '&filterDPEStep=' + dpeStep : '').concat(month ? '&filterMonthCreatedAt=' + month : '');
    }
}
function getRealEstateSoldModule(id) {
    return "/api/companies/".concat(id, "/capturama/real-estate-sold/preauthentication");
}
function getCadastreModule(id) {
    return "/api/companies/".concat(id, "/capturama/cadastre/preauthentication");
}
