const flowbite = require("flowbite-react/tailwind");

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './assets/**/*.tsx',
    flowbite.content(),
  ],
  theme: {
    extend: {
      colors: {
        'rgpd-admin': '#273d50',
        'rgpd-admin-bg': '#f3f4f6',
        'rgpd-red': '#f1416c',
        'rgpd-red-dark': '#d9214e',
        'rgpd-blue': '#0c4295',
        'rgpd-blue-dark': '#0b1755',
        'rgpd-blue-light': '#479fe2',
        'rgpd-white': '#fff',
        'rgpd-green': '#28a745',
        'rgpd-green-light': '#f2fff3',
        'rgpd-green-dark': '#218838',
      },
      cursor: {
        help: 'help',
      },
    },
  },
  plugins: [
    flowbite.plugin(),
  ],
};

