"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.rnicCoproDict = exports.BodaccDict = exports.BauxDict = exports.DPEDict = void 0;
var DPEDict = {
    'complRefLogement': 'Précision selon diagnostiqueur',
    'complEtageAppartement': 'Etage appartement',
    'dateReceptionDpe': 'Date de réception du DPE par le propriétaire',
    'classeBilanDpe': 'Classe de bilan DPE',
    'ancienneClasseBilanDpe': 'Ancienne classe bilan DPE',
    'nouvelleClasseEnergieDpe': 'Nouvelle classe bilan DPE',
    'surfaceHabitableLogement': 'Surface habitable du logement (m²)',
    'surfaceHabitableImmeuble': 'Surface habitable de l\'immeuble (m²)',
    'surfaceUtile': 'Surface utile (m²)',
    'labelBrut': 'Adresse brut',
    'anneeConstruction': 'Année de construction',
    'libTypeChauffage': 'Type d\'installation chauffage',
    'libTypeEnergie': 'Type d\'énergie',
    'epConso5Usages': 'Consommation annuelle 5 usages (kWef/an)',
    'epConso5UsagesM2': 'Consommation annuelle 5 usages par m² (kWef/m²/an)',
    'epConsoCh': 'Consommation annuelle chauffage (kWef/an)',
    'epConsoChDepensier': 'Consommation annuelle chauffage pour le scénario dépensier (kWef/an)',
    'epConsoEclairage': 'Consommation annuelle éclairage (kWef/an)',
    'epConsoEcsDepensier': 'Consommation annuelle ECS pour le scénario dépensier (kWef/an)',
    'epConsoFr': 'Consommation annuelle refroidissement (kWef/an)',
    'epConsoTotaleAuxiliaire': 'Consommation annuelle auxiliaires (kWef/an)',
    'cout5Usages': 'Coût annuel total 5 usages (€)',
    'coutCh': 'Coût total annuel chauffage (€)',
    'coutChDepensier': 'Coût total annuel chauffage pour le scénario dépensier (€)',
    'coutEclairage': 'Coût des éclairages (€)',
    'coutEcs': 'Coût de l\'ECS (€)',
    'coutEcsDepensier': 'Coût de l\'ECS pour le scénario dépensier (€)',
    'coutFr': 'Coût de refroidissement annuel (€)',
    'coutFrDepensier': 'Coût de refroidissement annuel pour le scénario dépensier (€)',
    'coutTotalAuxiliaire': 'Coût de l\'ensemble des auxiliaires (€)',
    'deperditionBaieVitree': 'Déperdition par les baies vitrées  (W/K)',
    'deperditionEnveloppe': 'Déperdition par l\'enveloppe (GV)',
    'deperditionMur': 'Déperdition par les murs (GV)',
    'deperditionPlancherBas': 'Déperdition par les planchers bas (GV)',
    'deperditionPlancherHaut': 'Déperdition par les planchers haut (GV)',
    'deperditionPontThermique': 'Déperdition par les ponts thermiques (GV)',
    'deperditionPorte': 'Déperdition par les portes (GV)',
    'deperditionRenouvellementAir': 'Déperdition par le renouvellement d\'air (GV)',
    'libQualEnveloppe': 'Qualité de l\'isolation de l\'enveloppe',
    'libQualMenuiserie': 'Qualité de l\'isolation des menuiseries',
    'libQualMurs': 'Qualité de l\'isolation des murs',
    'libQualPlancherBas': 'Qualité de l\'isolation des planchers',
    'libQualIphtt': 'Qualité d\'isolation de la toiture/planchers hauts toit terrasse',
    'libQualPhca': 'Qualité d\'isolation du toiture/planchers haut comblé aménagé',
    'libQualPhcp': 'Qualité d\'isolation du toiture/planchers haut comblé perdu',
    'ubat': 'Ubat (W/m²K)',
    'apportInterneCh': 'Apports internes totaux durant la saison de chauffe (kWh)',
    'apportInterneFr': 'Apports internes totaux durant la saison de froid (kWh)',
    'apportSolaireCh': 'Apports solaires totaux durant la saison de chauffe (kWh)',
    'apportSolaireFr': 'Apports solaires totaux durant la saison de froid (kWh)',
    'besoinEcs': 'Besoin de chauffage annuel total (kWh)',
    'besoinEcsDepensier': 'Besoin d\'ECS annuel total (kWh)',
    'besoinFr': 'Besoin de refroidissement annuel total (kWh)',
    'besoinFrDepensier': 'Besoin de refroidissement annuel total pour le scénario dépensier (kWh)',
};
exports.DPEDict = DPEDict;
var BauxDict = {
    'activitePrincipaleEtablissement': "Activité Principale de l'Établissement",
    'activitePrincipaleRegistreMetiersEtablissement': "Activité Principale de l'Établissement au Registre des Métiers",
    'activitePrincipaleUniteLegale': "Activité Principale de l'Unité Légale",
    'anneeCategorieEntreprise': "Année Catégorie de l'Entreprise",
    'caractereEmployeurEtablissement': "Caractère Employeur de l'Établissement",
    'categorieEntreprise': "Catégorie de l'Entreprise",
    'categorieJuridiqueUniteLegale': "Catégorie Juridique de l'Unité Légale",
    'codeCommuneEtablissement': "Code Commune de l'Établissement",
    'codePostalEtablissement': "Code Postal de l'Établissement",
    'complementAdresseEtablissement': "Complément d'Adresse de l'Établissement",
    'coordonneeLambertAbscisseEtablissement': "Coordonnée Lambert Abscisse de l'Établissement",
    'coordonneeLambertOrdonneeEtablissement': "Coordonnée Lambert Ordonnée de l'Établissement",
    'dateCreationEtablissement': "Date de Création de l'Établissement",
    'trancheEffectifsEtablissement': "Tranche effectifs de l'Établissement",
    'trancheEffectifsUniteLegale': "Tranche effectifs de l'Unité Légale",
    'anneeEffectifsEtablissement': "Année effectifs de l'Établissement",
    'anneeEffectifsUniteLegale': "Année effectifs de l'Unité Légale",
    'dateCreationUniteLegale': "Date de Création de l'Unité Légale",
    'dateDebut': 'Date de Début',
    'dateDernierTraitementEtablissement': "Date du Dernier Traitement de l'Établissement",
    'dateDernierTraitementUniteLegale': "Date du Dernier Traitement de l'Unité Légale",
    'denominationUsuelleEtablissement': "Dénomination usuelle de l'Établissement",
    'denominationUniteLegale': "Dénomination de l'Unité Légale",
    'denominationUsuelle1UniteLegale': "Dénomination usuelle 1 de l'Unité Légale",
    'economieSocialeSolidaireUniteLegale': "Économie Sociale et Solidaire de l'Unité Légale",
    'enseigne1Etablissement': "Enseigne 1 de l'Établissement",
    'etablissementSiege': 'Établissement Siège',
    'etatAdministratifEtablissement': "État Administratif de l'Établissement",
    'etatAdministratifUniteLegale': "État Administratif de l'Unité Légale",
    'identifiantAdresseEtablissement': "Identifiant Adresse de l'Établissement",
    'identifiantAssociationUniteLegale': "Identifiant Association de l'Unité Légale",
    'indiceRepetitionEtablissement': "Incide de répétition de l'Établissement",
    'libelleCommuneEtablissement': "Libellé Commune de l'Établissement",
    'libelleVoieEtablissement': "Libellé Voie de l'Établissement",
    'nic': 'NIC',
    'nicSiegeUniteLegale': "NIC Siège de l'Unité Légale",
    'nombrePeriodesEtablissement': "Nombre de Périodes de l'Établissement",
    'nombrePeriodesUniteLegale': "Nombre de Périodes de l'Unité Légale",
    'nomenclatureActivitePrincipaleEtablissement': "Nomenclature Activité Principale de l'Établissement",
    'nomenclatureActivitePrincipaleUniteLegale': "Nomenclature Activité Principale de l'Unité Légale",
    'numeroVoieEtablissement': "Numéro Voie de l'Établissement",
    'siren': 'SIREN',
    'siret': 'SIRET',
    'statutDiffusionEtablissement': "Statut de Diffusion de l'Établissement",
    'statutDiffusionUniteLegale': "Statut de Diffusion de l'Unité Légale",
    'typeVoieEtablissement': "Type de Voie de l'Établissement",
    'nameCopro': 'Nom de la copropriété',
    'gestionnaireCopro': 'Gestionnaire de la copropriété',
    'leaseEndDate': 'Date de fin de mandat',
};
exports.BauxDict = BauxDict;
var BodaccDict = __assign({ 'bodaccId': 'ID Bodacc', 'siren': 'SIREN', 'publicationavis': 'Type de publication', 'dateparution': 'Date de parution', 'id': 'ID', 'publicationavisFacette': 'Facette de publication', 'parution': 'Numéro de parution', 'numeroannonce': "Numéro d'annonce", 'typeavis': "Type d'avis", 'typeavisLib': "Libellé du type d'avis", 'familleavis': "Famille d'avis", 'familleavisLib': "Libellé de la famille d'avis", 'numerodepartement': 'Numéro de département', 'departementNomOfficiel': 'Nom officiel du département', 'regionCode': 'Code de région', 'regionNomOfficiel': 'Nom officiel de la région', 'tribunal': 'Tribunal', 'commercant': 'Commerçant', 'ville': 'Ville', 'registre': 'Registre', 'cp': 'Code postal', 'pdfParutionSubfolder': 'Sous-dossier PDF de parution', 'ispdfUnitaire': 'PDF unitaire', 'listepersonnes': 'Liste des personnes', 'listeetablissements': 'Liste des établissements', 'jugement': 'Jugement', 'acte': 'Acte', 'modificationsgenerales': 'Modifications générales', 'radiationaurcs': 'Radiation au RCS', 'depot': 'Dépôt', 'listeprecedentexploitant': 'Liste des précédents exploitants', 'listeprecedentproprietaire': 'Liste des précédents propriétaires', 'divers': 'Divers', 'parutionavisprecedent': "Parution de l'avis précédent", 'etablissementLinked': 'Établissement lié à l\'avis' }, BauxDict);
exports.BodaccDict = BodaccDict;
var rnicCoproDict = {
    'numeroDimmatriculation': "Numéro d'immatriculation",
    'epci': 'EPCI',
    'commune': 'Commune',
    'dateDimmatriculation': "Date d'immatriculation",
    'dateDeLaDerniereMaj': 'Date de la dernière mise à jour',
    'typeDeSyndicBenevoleProfessionnelNonConnu': 'Type de syndic (bénévole / professionnel / non connu)',
    'identificationDuRepresentantLegalRaisonSocialeEtLeNume': 'Identification du représentant légal (raison sociale et numéro)',
    'siretRepresentantLegalSiExiste': 'SIRET du représentant légal (si existe)',
    'codeApe': 'Code APE',
    'communeDuRepresentantLegal': 'Commune du représentant légal',
    'mandatEnCoursDansLaCopropriete': 'Mandat en cours dans la copropriété',
    'dateDeFinDuDernierMandat': 'Date de fin du dernier mandat',
    'nomDusageDeLaCopropriete': "Nom d'usage de la copropriété",
    'adresseDeReference': 'Adresse de référence',
    'numeroEtVoieAdresseDeReference': "Numéro et voie de l'adresse de référence",
    'codePostalAdresseDeReference': "Code postal de l'adresse de référence",
    'communeAdresseDeReference': "Commune de l'adresse de référence",
    'adresseComplementaire1': 'Adresse complémentaire 1',
    'adresseComplementaire2': 'Adresse complémentaire 2',
    'adresseComplementaire3': 'Adresse complémentaire 3',
    'nombreDadressesComplementaires': "Nombre d'adresses complémentaires",
    'long': 'Longitude',
    'lat': 'Latitude',
    'dateDuReglementDeCopropriete': 'Date du règlement de copropriété',
    'residenceService': 'Résidence service',
    'syndicatCooperatif': 'Syndicat coopératif',
    'syndicatPrincipalOuSyndicatSecondaire': 'Syndicat principal ou syndicat secondaire',
    'siSecondaireNDimmatriculationDuPrincipal': "Si secondaire, N° d'immatriculation du principal",
    'nombreDaslAuxquellesEstRattacheLeSyndicatDeCoproprietai': "Nombre d'ASL auxquelles est rattaché le syndicat de copropriétaires",
    'nombreDafulAuxquellesEstRattacheLeSyndicatDeCoproprieta': "Nombre d'AFUL auxquelles est rattaché le syndicat de copropriétaires",
    'nombreDunionsDeSyndicatsAuxquellesEstRattacheLeSyndicat': "Nombre d'unions de syndicats auxquelles est rattaché le syndicat",
    'nombreTotalDeLots': 'Nombre total de lots',
    'nombreTotalDeLotsAUsageDhabitationDeBureauxOuDeComme': "Nombre total de lots à usage d'habitation, de bureaux ou de commerce",
    'nombreDeLotsAUsageDhabitation': "Nombre de lots à usage d'habitation",
    'nombreDeLotsDeStationnement': 'Nombre de lots de stationnement',
    'nombreDarretesRelevantDuCodeDeLaSantePubliqueEnCours': "Nombre d'arrêtés relevant du Code de la santé publique en cours",
    'nombreDarretesDePerilSurLesPartiesCommunesEnCours': "Nombre d'arrêtés de péril sur les parties communes en cours",
    'nombreDarretesSurLesEquipementsCommunsEnCours': "Nombre d'arrêtés sur les équipements communs en cours",
    'periodeDeConstruction': 'Période de construction',
    'referenceCadastrale1': 'Référence cadastrale 1',
    'codeInseeCommune1': 'Code INSEE commune 1',
    'prefixe1': 'Préfixe 1',
    'section1': 'Section 1',
    'numeroParcelle1': 'Numéro de parcelle 1',
    'referenceCadastrale2': 'Référence cadastrale 2',
    'codeInseeCommune2': 'Code INSEE commune 2',
    'prefixe2': 'Préfixe 2',
    'section2': 'Section 2',
    'numeroParcelle2': 'Numéro de parcelle 2',
    'referenceCadastrale3': 'Référence cadastrale 3',
    'codeInseeCommune3': 'Code INSEE commune 3',
    'prefixe3': 'Préfixe 3',
    'section3': 'Section 3',
    'numeroParcelle3': 'Numéro de parcelle 3',
    'nombreDeParcellesCadastrales': 'Nombre de parcelles cadastrales',
    'nomQp2015': 'Nom QP 2015',
    'codeQp2015': 'Code QP 2015',
    'nomQp2024': 'Nom QP 2024',
    'codeQp2024': 'Code QP 2024',
    'coproDansAcv': 'Copropriété dans ACV',
    'coproDansPvd': 'Copropriété dans PVD',
    'coproAidee': 'Copropriété aidée',
    'codeOfficielCommune': 'Code officiel de la commune',
    'nomOfficielCommune': 'Nom officiel de la commune',
    'codeOfficielArrondissementCommune': "Code officiel de l'arrondissement de la commune",
    'nomOfficielArrondissementCommune': "Nom officiel de l'arrondissement de la commune",
    'codeOfficielEpci': "Code officiel de l'EPCI",
    'nomOfficielEpci': "Nom officiel de l'EPCI",
    'codeOfficielDepartement': 'Code officiel du département',
    'nomOfficielDepartement': 'Nom officiel du département',
    'codeOfficielRegion': 'Code officiel de la région',
    'nomOfficielRegion': 'Nom officiel de la région',
    'nomQp': 'Nom QP',
    'codeQp': 'Code QP',
    'id': 'ID',
    'typeteledeclarant': 'Type télédéclarant',
    'typemandat': 'Type mandat',
    'etatcopropriete': 'État copropriété',
    'raisonsocialeounomsyndic': 'Raison sociale ou nom syndic',
    'siret': 'SIRET',
    'communeetablissement': "Commune d'établissement",
    'datedernieremodif': 'Date dernière modification',
    'datedissolution': 'Date dissolution',
    'typeimmat': 'Type immatriculation',
    'detailsInforattachementId': 'Détails information rattachement ID',
    'detailsInforattachementAdminprovisoire': 'Détails information rattachement administrateur provisoire',
    'detailsInforattachementTypejustifmandat': 'Détails information rattachement type justificatif mandat',
    'detailsInforattachementNomfichierjustifmandat': 'Détails information rattachement nom fichier justificatif mandat',
    'detailsInforattachementJustifmandat': 'Détails information rattachement justificatif mandat',
    'detailsInforattachementDatedebutmandat': 'Détails information rattachement date début mandat',
    'detailsInforattachementDatefinmandat': 'Détails information rattachement date fin mandat',
    'detailsInforattachementDateteleversement': 'Détails information rattachement date téléversement',
    'detailsInforattachementIddocument': 'Détails information rattachement ID document',
    'detailsIdentificationId': 'Détails identification ID',
    'detailsIdentificationIdcopropriete': 'Détails identification ID copropriété',
    'detailsIdentificationNumimmatriculationcopropriete': 'Détails identification numéro immatriculation copropriété',
    'detailsIdentificationNomcopropriete': 'Détails identification nom copropriété',
    'detailsIdentificationDatereglementcopropriete': 'Détails identification date règlement copropriété',
    'detailsIdentificationSiret': 'Détails identification SIRET',
    'detailsIdentificationAdresseprincipaleId': 'Détails identification adresse principale ID',
    'detailsIdentificationAdresseprincipaleNumvoiegeoloc': 'Détails identification adresse principale numéro voie géolocalisation',
    'detailsIdentificationAdresseprincipaleVoie': 'Détails identification adresse principale voie',
    'detailsIdentificationAdresseprincipaleLieudit': 'Détails identification adresse principale lieu-dit',
    'detailsIdentificationAdresseprincipaleCodepostal': 'Détails identification adresse principale code postal',
    'detailsIdentificationAdresseprincipaleCommune': 'Détails identification adresse principale commune',
    'detailsIdentificationAdresseprincipaleFlatadresse': 'Détails identification adresse principale adresse plate',
    'detailsIdentificationAdresseprincipaleAdressenonstructuree': 'Détails identification adresse principale adresse non structurée',
    'detailsIdentificationAdresseprincipaleX': 'Détails identification adresse principale X',
    'detailsIdentificationAdresseprincipaleY': 'Détails identification adresse principale Y',
    'detailsIdentificationAdresseprincipaleCodeinsee': 'Détails identification adresse principale code INSEE',
    'detailsIdentificationAdresseprincipaleIdign': 'Détails identification adresse principale ID IGN',
    'detailsIdentificationAdresseprincipaleType': 'Détails identification adresse principale type',
    'detailsIdentificationAdresseprincipaleNomcopro': 'Détails identification adresse principale nom copropriété',
    'detailsIdentificationAdresseprincipaleLat': 'Détails identification adresse principale latitude',
    'detailsIdentificationAdresseprincipaleLon': 'Détails identification adresse principale longitude',
    'detailsIdentificationAdresseprincipaleVoiegeoloc': 'Détails identification adresse principale voie géolocalisation',
    'detailsIdentificationAdresseprincipaleCodepostalgeoloc': 'Détails identification adresse principale code postal géolocalisation',
    'detailsIdentificationAdresseprincipaleCommunegeoloc': 'Détails identification adresse principale commune géolocalisation',
    'detailsIdentificationAdresseprincipaleCodeinseegeoloc': 'Détails identification adresse principale code INSEE géolocalisation',
    'detailsIdentificationParcelle': 'Détails identification parcelle',
    'detailsIdentificationResidenceservice': 'Détails identification résidence service',
    'detailsIdentificationSyndicatcooperatif': 'Détails identification syndicat coopératif',
    'detailsIdentificationSyndicatprincipal': 'Détails identification syndicat principal',
    'detailsIdentificationNumimmatriculationcoproprieteprincipale': 'Détails identification numéro immatriculation copropriété principale',
    'detailsIdentificationNbasl': 'Détails identification nombre ASL',
    'detailsIdentificationNbaful': 'Détails identification nombre AFUL',
    'detailsIdentificationNbunionssyndicats': 'Détails identification nombre unions syndicats',
    'detailsIdentificationNblots': 'Détails identification nombre lots',
    'detailsIdentificationNblotshabburcom': 'Détails identification nombre lots habitation, bureaux, commerce',
    'detailsIdentificationNblotshab': 'Détails identification nombre lots habitation',
    'detailsIdentificationNblotsstationnement': 'Détails identification nombre lots stationnement',
    'detailsProceduresId': 'Détails procédures ID',
    'detailsProceduresFlagsecuriteinsalubritepartiescom': 'Détails procédures flag sécurité insalubrité parties communes',
    'detailsProceduresFlaginsalubritepartiescom': 'Détails procédures flag insalubrité parties communes',
    'detailsProceduresFlagperilpartiescom': 'Détails procédures flag péril parties communes',
    'detailsProceduresFlagequipementscom': 'Détails procédures flag équipements communs',
    'detailsProceduresFlagmandatadhoc': 'Détails procédures flag mandat ad hoc',
    'detailsProceduresFlagordonnancecarence': 'Détails procédures flag ordonnance carence',
    'detailsDonneestechniquesId': 'Détails données techniques ID',
    'detailsDonneestechniquesPeriodeconstruction': 'Détails données techniques période construction',
    'detailsDonneestechniquesAnneeconstruction': 'Détails données techniques année construction',
    'detailsDonneestechniquesTypechauffage': 'Détails données techniques type chauffage',
    'detailsDonneestechniquesChauffagecollectifurbain': 'Détails données techniques chauffage collectif urbain',
    'detailsDonneestechniquesEnergiechauffagecollectifnonurbain': 'Détails données techniques énergie chauffage collectif non urbain',
    'detailsDonneestechniquesNbascenseurs': "Détails données techniques nombre d'ascenseurs",
    'detailsDonneestechniquesNbbatimentstotal': 'Détails données techniques nombre batiments total',
    'detailsDonneestechniquesClassementenergetiquecomplet': 'Détails données techniques classement énergétique complet',
    'detailsDonneestechniquesEnergiechauffagecollectifnonurbaintra': 'Détails données techniques énergie chauffage collectif non urbain tra',
    'detailsDonneestechniquesTypechauffagetranslated': 'Détails données techniques type chauffage traduit',
    'detailsDonneestechniquesPeriodeconstructiontranslated': 'Détails données techniques période construction traduit',
    'detailsDonneestechniquesAnneeconstructiontranslated': 'Détails données techniques année construction traduit',
    'detailsDonneestechniquesPpt': 'Détails données techniques PPT',
    'detailsDonneestechniquesPptdate': 'Détails données techniques PPT date',
    'detailsDonneesfinancieresId': 'Détails données financières ID',
    'detailsDonneesfinancieresDateag': 'Détails données financières date AG',
    'detailsDonneesfinancieresDatecloturecomptes': 'Détails données financières date clôture comptes',
    'detailsDonneesfinancieresChargesoperationscourantes': 'Détails données financières charges opérations courantes',
    'detailsDonneesfinancieresChargesoperationsexceptionnelles': 'Détails données financières charges opérations exceptionnelles',
    'detailsDonneesfinancieresMontantimpayescoproprietaires': 'Détails données financières montant impayés copropriétaires',
    'detailsDonneesfinancieresPresenceemployes': 'Détails données financières présence employés',
    'detailsDonneesfinancieresNbcoproprietairesimpayes': 'Détails données financières nombre copropriétaires impayés',
    'detailsDonneesfinancieresMontantdettes': 'Détails données financières montant dettes',
    'detailsDonneesfinancieresMontantfondtravaux': 'Détails données financières montant fonds travaux',
    'detailsDonneesfinancieresDatedebutexercice': 'Détails données financières date début exercice',
    'detailsDonneesfinancieresPremierexercicecomptable': 'Détails données financières premier exercice comptable',
    'detailsDonneesfinancieresComptesnonapprouves': 'Détails données financières comptes non approuvés',
    'rnicNumeroEtVoieAdresseDeReference': 'RNIC numéro et voie adresse de référence',
    'rnicCommune': 'RNIC commune',
    'rnicImmatriculation': 'RNIC immatriculation',
    'similarityScore': 'Similarity score',
    'matchedBanIdAdr': 'Matched BAN ID adresse',
    'matchedBanNomCom': 'Matched BAN nom commune',
    'matchedBanNumero': 'Matched BAN numéro',
    'matchedBanNomVoie': 'Matched BAN nom voie',
    'matchedBanRep': 'Matched BAN rep',
    'rn': 'RN',
};
exports.rnicCoproDict = rnicCoproDict;
