"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DpeStepsEnum = void 0;
var DpeStepsEnum;
(function (DpeStepsEnum) {
    DpeStepsEnum[DpeStepsEnum["NEW"] = 1] = "NEW";
    DpeStepsEnum[DpeStepsEnum["NO_OWNER"] = 2] = "NO_OWNER";
    DpeStepsEnum[DpeStepsEnum["IDENTIFIED"] = 3] = "IDENTIFIED";
    DpeStepsEnum[DpeStepsEnum["IN_PROCESS"] = 4] = "IN_PROCESS";
    DpeStepsEnum[DpeStepsEnum["PROCESSED"] = 5] = "PROCESSED";
})(DpeStepsEnum || (exports.DpeStepsEnum = DpeStepsEnum = {}));
