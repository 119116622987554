"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLabelByValue = exports.getValueByLabel = exports.roles = void 0;
var roles = [
    { label: 'Référant RGPD', value: -1 },
    { label: 'Informaticien', value: 1 },
    { label: 'Réprésentant légal', value: 2 },
    { label: 'Transactionnaire', value: 3 },
    { label: 'Directeur commercial', value: 4 },
    { label: 'Directeur agence', value: 5 },
    { label: 'Comptable', value: 6 },
    { label: 'Gestionnaire', value: 7 },
    { label: 'Assistant', value: 8 },
    { label: 'Juriste', value: 9 },
    { label: 'Autre', value: 10 },
    { label: 'Directeur administratif financier', value: 11 },
    { label: 'Responsable service', value: 12 },
    { label: 'Secrétaire', value: 13 },
];
exports.roles = roles;
var getValueByLabel = function (label) {
    var foundRole = roles.find(function (r) { return r.label === label; });
    return foundRole ? foundRole.value : null;
};
exports.getValueByLabel = getValueByLabel;
var getLabelByValue = function (value) {
    var foundRole = roles.find(function (r) { return r.value === value; });
    return foundRole ? foundRole.label : null;
};
exports.getLabelByValue = getLabelByValue;
